<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="d-flex align-center justify-space-between">
                    <h1 class="text-h4" style="flex-grow: 1">
                        Venteliste<span v-if="ready && !loading">&nbsp;({{ personer.length }})</span>
                    </h1>
                    <v-btn color="primary" v-on:click="create">
                        Ny bruker
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-toolbar class="mt-6" color="grey lighten-4" elevation="4">
                    <div class="mr-7">
                        <persons-actions v-model="valgtePersoner" :headers="headers" :alle="alleValgt"></persons-actions>
                    </div>
                    <v-col cols="3">
                        <v-text-field v-model="sok" label="Søk etter" append-icon="mdi-magnify" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="sorgGruppe" :items="sorgGruppeFilter" item-text="navn" item-value="id" label="Sorgruppe" hide-details></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="forstegangssamtaleDato"
                            :items="forstegangssamtaleDatoFilter"
                            label="Førstegangsamtale"
                            item-text="label"
                            item-value="value"
                            hide-details
                        ></v-select>
                    </v-col>
                </v-toolbar>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        v-model="valgte"
                        :headers="headers"
                        :items="personer"
                        :custom-sort="customSort"
                        sort-by="fulltNavn"
                        :items-per-page="-1"
                        show-select
                        must-sort
                        hide-default-footer
                    >
                        <template v-slot:item.fulltNavn="{ item, index }">
                            <td v-on:click="edit(index)" class="v-data-table-item--link">
                                <a class="text-decoration-none"> {{ item.fulltNavn }}</a>
                            </td>
                        </template>
                        <template v-slot:item.sorgType="{ item }">
                            {{ item.sorginformasjon && item.sorginformasjon.sorgType ? item.sorginformasjon.sorgType.navn : '' }}
                        </template>
                        <template v-slot:item.forstegangssamtaleDato="{ item }">
                            {{
                                item.sorginformasjon && item.sorginformasjon.forstegangssamtaleDato
                                    ? item.sorginformasjon.forstegangssamtaleDato.format('DD.MM.YYYY')
                                    : ''
                            }}
                        </template>
                        <template v-slot:item.sendtSMSDato="{ item }">
                            {{
                                item.sorginformasjon && item.sorginformasjon.sendtSMSDato
                                    ? item.sorginformasjon.sendtSMSDato.format('DD.MM.YYYY')
                                    : ''
                            }}
                        </template>
                        <template v-slot:item.tilordnetSorgGruppeLopenr="{ item }">
                            {{
                                item.sorginformasjon && item.sorginformasjon.tilordnetSorgGruppeLopenr
                                    ? item.sorginformasjon.tilordnetSorgGruppeLopenr
                                    : ''
                            }}
                        </template>
                        <template v-slot:item.opprettet="{ item }"> {{ item.opprettet | format('DD.MM.YYYY') }} </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <person-dialog ref="personDialog" v-model="person" :personer="sortertePersoner" rolle="BRUKER_SORG" v-on:closed="update"> </person-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import PersonDialog from '@/pages/persons/PersonDialog.vue';
import PersonsActions from '@/pages/persons/Actions.vue';

export default {
    name: 'GriefGroups',
    components: {
        PersonDialog,
        PersonsActions,
    },
    data() {
        return {
            ready: false,
            loading: false,

            headers: [
                { text: 'Navn', value: 'fulltNavn', sortable: true },
                { text: 'Gruppe', value: 'sorgType', sortable: true },
                { text: 'Førstegangssamtale', value: 'forstegangssamtaleDato', sortable: true },
                { text: 'Sendt SMS', value: 'sendtSMSDato', sortable: true },
                { text: 'Tilordnet gruppe', value: 'tilordnetSorgGruppeLopenr', sortable: true },
                { text: 'Opprettet', value: 'opprettet', sortable: true },
            ],

            allePersoner: [],
            sortertePersoner: [],
            valgte: [],
            personer: [],
            person: {},

            grupper: {},

            sok: '',
            sorgGruppe: 'uten',
            sorgGruppeFilter: [
                { id: 'uten', navn: 'Uten sorgruppe' },
                { id: 'ingen', navn: 'Aldri hatt sorgruppe' },
            ],

            forstegangssamtaleDato: '',
            forstegangssamtaleDatoFilter: [
                { value: '', label: '(alle)' },
                { value: 'no', label: 'Ikke hatt' },
                { value: 'yes', label: 'Har hatt' },
            ],
        };
    },
    computed: {
        valgtePersoner: function () {
            return this.valgte.length ? this.valgte : this.sortertePersoner;
        },
        alleValgt: function () {
            return !this.valgte.length || this.valgte.length == this.personer.length;
        },
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },
    watch: {
        sorgGruppe: function (value) {
            this.sorgGruppe = value;
            this.update();
        },
        sok: function (value) {
            this.sok = value;
            if (this.ready) {
                this.filter();
            }
        },
        forstegangssamtaleDato: function (value) {
            this.forstegangssamtaleDato = value;
            this.update();
        },
    },
    created: async function () {
        await this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),

        /**
         * update
         */
        update: async function () {
            try {
                this.loading = true;

                this.metoder = [];
                let response = await this.request({
                    method: 'get',
                    url: '/sorggruppe',
                });
                if (response && Array.isArray(response)) {
                    for (const gruppe of response) {
                        this.grupper[gruppe.id] = gruppe.lopenr;
                    }
                }

                response = await this.request({
                    method: 'get',
                    url: '/person/bruker/sorg',
                    params: { sorgGruppe: this.sorgGruppe },
                });

                this.allePersoner = [];
                if (response && Array.isArray(response)) {
                    for (let person of response) {
                        if (person.status == 'AKTIV') {
                            person = await this.formatPerson(person);
                            if(person.sorginformasjon && person.sorginformasjon.tilordnetSorgGruppeId) {
                                person.sorginformasjon.tilordnetSorgGruppeLopenr = typeof this.grupper[person.sorginformasjon.tilordnetSorgGruppeId] != "undefined" ? this.grupper[person.sorginformasjon.tilordnetSorgGruppeId] : '';
                            }

                            this.allePersoner.push(person);
                        }
                    }
                }

                this.filter();
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * filter
         */
        filter: function () {
            const personer = [];
            const sok = this.sok.toLowerCase();
            for (const person of this.allePersoner) {
                if (
                    (!sok.length || person.sokeString.indexOf(sok) !== -1) &&
                    person.status != 'SLUTTET' &&
                    (!this.forstegangssamtaleDato ||
                        (this.forstegangssamtaleDato == 'no' && person.sorginformasjon && !person.sorginformasjon.forstegangssamtaleDato) ||
                        (this.forstegangssamtaleDato == 'yes' && person.sorginformasjon && person.sorginformasjon.forstegangssamtaleDato))
                ) {
                    personer.push(person);
                }
            }
            this.personer = personer;
        },

        /**
         * edit
         */
        edit: function (index) {
            this.$refs.personDialog.edit(index);
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index.length) {
                items.sort((a, b) => {
                    switch (index[0]) {
                        case 'fulltNavn':
                            a = a.etternavn + ' ' + a.fornavn;
                            b = b.etternavn + ' ' + b.fornavn;
                            break;

                        case 'opprettet':
                            a = a.opprettet ? a.opprettet : 0;
                            b = b.opprettet ? b.opprettet : 0;
                            return isDesc[0] ? a - b : b - a;

                        case 'sorgType':
                            a = a.sorginformasjon && a.sorginformasjon.sorgType ? a.sorginformasjon.sorgType.navn : '';
                            b = b.sorginformasjon && b.sorginformasjon.sorgType ? b.sorginformasjon.sorgType.navn : '';
                            break;

                        case 'forstegangssamtaleDato':
                            a = a.sorginformasjon && a.sorginformasjon.forstegangssamtaleDato ? a.sorginformasjon.forstegangssamtaleDato : 0;
                            b = b.sorginformasjon && b.sorginformasjon.forstegangssamtaleDato ? b.sorginformasjon.forstegangssamtaleDato : 0;
                            return isDesc[0] ? a - b : b - a;

                        default:
                            a = a.id;
                            b = b.id;
                    }

                    if (!isDesc[0]) {
                        return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' });
                    } else {
                        return b.localeCompare(a, 'no', { numeric: true, sensitivity: 'base' });
                    }
                });
            }
            this.sortertePersoner = items;
            return items;
        },

        /**
         * create
         */
        create: function () {
            this.$refs.personDialog.create();
        },
    },
};
</script>
<style lang="scss">
.v-data-table-item--link {
    cursor: pointer;
}
</style>
